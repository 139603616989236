import { SynapsConfig, SynapsInit, SynapsMessages } from "./types/sdkTypes";
import { Loader } from "./utils/loader";

let verifyIframe: HTMLIFrameElement | undefined = undefined;
let synapsInitConfig: SynapsInit | undefined = undefined;

const SynapsVerifyConfig: SynapsConfig = {
  verifyUrl: "https://verify.synaps.io",
};

const getContainer = (mode: "modal" | "embed", containerId: string) => {
  let container: HTMLElement | null = null;

  if (mode === "modal") {
    container = document.createElement("div");
    container.id = containerId || "synaps-wrapper";

    document.body.appendChild(container);
  } else {
    container = document.getElementById(containerId || "synaps-wrapper");
  }

  return container;
};

const init = (initConfig: SynapsInit) => {
  const {
    containerId = "synaps-wrapper",
    onFinish,
    handleSign,
    mode = "modal",
    service = "individual",
  } = initConfig;

  if (service === "corporate") {
    SynapsVerifyConfig.verifyUrl = "https://verify-v3.synaps.io";
  }

  const container = getContainer(mode, containerId);

  if (!container) {
    return console.error(`No element found with id ${containerId}`);
  }

  if (verifyIframe) {
    if (container.contains(verifyIframe)) {
      container.removeChild(verifyIframe);
    }
    verifyIframe.remove();
  }

  // Add css for loader
  Loader.init();

  // Init event listeners
  initEventListeners({ onFinish, handleSign });

  // Create iframe if embed mode
  if (mode === "embed") {
    createIframe(initConfig);
  }

  // Save config in var
  synapsInitConfig = initConfig;
};

const createIframe = (initConfig: SynapsInit) => {
  const {
    containerId,
    sessionId,
    lang,
    mode = "modal",
    walletAddress,
    withFinishButton,
    tier,
    service,
  } = initConfig;

  const container = getContainer(mode, containerId || "synaps-wrapper");

  if (!container) {
    return console.error(`No element found with id ${containerId}`);
  }

  Loader.show(mode === "modal" ? document.body : container, mode);

  const iframe = document.createElement("iframe");

  const iframeUrl = new URL(SynapsVerifyConfig.verifyUrl);

  if (service === "corporate") {
    iframeUrl.searchParams.append("service", service);
  }

  iframeUrl.searchParams.append("session_id", sessionId);
  iframeUrl.searchParams.append("type", mode);
  if (lang) iframeUrl.searchParams.append("lang", lang);
  if (walletAddress)
    iframeUrl.searchParams.append("wallet_address", walletAddress);
  if (withFinishButton)
    iframeUrl.searchParams.append("with_finish_button", "true");
  if (tier) iframeUrl.searchParams.append("tier", tier);

  iframe.src = iframeUrl.href;
  // @ts-ignore
  iframe.allowtransparency = true;
  iframe.allowFullscreen = true;
  iframe.allow = "camera; microphone; midi; encrypted-media; clipboard-write;";

  if (mode === "modal") {
    iframe.style.position = "absolute";
    iframe.style.left = "0";
    iframe.style.top = "0";
  }

  iframe.style.width = "100%";
  iframe.style.height = "100%";

  iframe.style.opacity = "0";
  iframe.style.zIndex = "-1";

  iframe.style.border = "none";
  iframe.style.overflow = "hidden";
  iframe.style.backgroundColor = "none transparent";

  verifyIframe = iframe;

  container.appendChild(iframe);
};

const config = ({ verifyUrl }: Partial<SynapsConfig>) => {
  if (verifyUrl) SynapsVerifyConfig.verifyUrl = verifyUrl;
};

const hide = () => {
  if (!verifyIframe) {
    console.error("No iframe found");
    return;
  }

  // Hide loader
  Loader.hide();

  verifyIframe.style.zIndex = "-1";
  verifyIframe.style.opacity = "0";
};

const show = () => {
  const { mode = "modal" } = synapsInitConfig || {};
  // Create iframe if modal mode
  if (mode === "modal" && synapsInitConfig) {
    createIframe(synapsInitConfig);
  } else if (!verifyIframe) {
    console.error("No iframe found");
    return;
  } else {
    verifyIframe.style.opacity = "1";
    verifyIframe.style.zIndex = "999";
  }
};

const initEventListeners = ({
  onFinish,
  handleSign,
}: Pick<SynapsInit, "onFinish" | "handleSign">) => {
  window.addEventListener(
    "message",
    (event: MessageEvent<SynapsMessages>) => {
      // Prevent other messages
      event.stopImmediatePropagation();

      if (
        event.origin.replace(/\/$/, "") !==
        SynapsVerifyConfig.verifyUrl.replace(/\/$/, "")
      ) {
        return;
      }

      if (event.data.type === "ready") {
        Loader.hide();
        if (verifyIframe) {
          verifyIframe.style.opacity = "1";
          verifyIframe.style.zIndex = "999";
        }
      }

      if (event.data.type === "finish" || event.data.type === "close") {
        const { mode = "modal" } = synapsInitConfig || {};
        if (mode === "modal") hide();
        onFinish && onFinish();
      }

      if (event.data.type === "request_sign") {
        // Handle sign request
        handleSign &&
          handleSign(event.data.data.payload).then((signature) => {
            // Send signature back to iframe
            verifyIframe?.contentWindow?.postMessage(
              { type: "sign", data: { signature } },
              SynapsVerifyConfig.verifyUrl
            );
          });
      }

      if (event.data.type === "update") {
        verifyIframe?.contentWindow?.postMessage(
          { type: "update", data: {} },
          SynapsVerifyConfig.verifyUrl
        );
      }
    },
    true
  );
};

export const Synaps = {
  init,
  config,
  show,
  hide,
};
